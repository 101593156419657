.receipt-print {
  width: 68mm;
  height: 190mm;
  font-family: Arial, Helvetica, sans-serif !important;
  margin-left: 0.5rem;
}

@page {
  size: auto; /* auto is the initial value */
  /* this affects the margin in the printer settings */
  margin: 0;
  width: 100% !important;
}

.p-print {
  margin-bottom: 0px;
  margin-top: 0px;
}
.paper-68mm {
  padding-left: 5pt;
  padding-right: 5pt;
}
.txt-print-h1 {
  /* font-family: Arial, Helvetica, sans-serif !important; */
  font-size: 12pt;
  text-align: center;
}
.txt-print-h2 {
  /* font-family: Arial, Helvetica, sans-serif !important; */
  font-size: 12pt;
  text-align: center;
}
.txt-print-h3 {
  /* font-family: Arial, Helvetica, sans-serif !important; */
  font-size: 9pt;
  text-align: center;
}
.txt-print-footer {
  /* font-family: Arial, Helvetica, sans-serif; */
  font-size: 9pt;
  text-align: center !important;
}
.txt-print-tagline {
  /* font-family: Arial, Helvetica, sans-serif; */
  font-size: 9pt;
  text-align: center !important;
  font-weight: bold;
}
.txt-print-section-h2 {
  /* font-family: Arial, Helvetica, sans-serif !important; */
  font-size: 11pt;
  text-align: left;
  /* margin-left: 20px; */
}
.txt-print-section {
  /* font-family: Arial, Helvetica, sans-serif !important; */
  font-size: 9pt;
  text-align: left;
  /* margin-left: 25px; */
}
.txt-print-contact {
  /* font-family: Arial, Helvetica, sans-serif; */
  font-size: 10pt;
}
.rmp {
  margin-bottom: 0px;
}
/* Apply this to your `table` element. */
.table-print {
  border-collapse: collapse;
  /* margin-left: 20px; */
  /* text-align: center; */
}

/* And this to your table's `td` elements. */
/* .table-print td {
  padding: 0;
  margin: 0;
} */
.txt-print {
  font-family: serif;
  font-size: 6pt;
}
.txt-price {
  text-align: right !important;
}
.img-print {
  display: block !important;
  width: 90px !important;
}

.table-print-contact {
  border-collapse: collapse;
  /* margin-left: 25px; */
}

.table-print-contact td {
  padding: 0px;
  margin: 0px;
}

@media screen {
  .receipt-print {
    visibility: hidden;
  }
}
