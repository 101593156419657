@import "../../node_modules/bootstrap/scss/bootstrap.scss";

@import "../../node_modules/react-image-gallery/styles/scss/image-gallery.scss";
body {
  margin: 0;
  padding: 0;
  font-family: "Nunito" !important;
  background-color: white;
  // overflow-y: auto;
}

ul {
  padding: 0 !important;
  margin: 0 !important;
}

li {
  list-style: none;
}
a {
  text-decoration: none !important;
}

// @font-face {
//   font-family: "Comfortaa";
//   src: local("Comfortaa"),
//     url("./../fonts/Comfortaa/Comfortaa-VariableFont_wght.ttf")
//       format("truetype");
//   font-weight: 300 700;
// }
// @font-face {
//   font-family: "OpenSans";
//   src: local("OpenSans"),
//     url("./../fonts/OpenSans/OpenSans-VariableFont_wdth,wght.ttf")
//       format("truetype");
//   font-weight: 300 800;
// }
@font-face {
  font-family: "Nunito";
  src: local("Nunito"),
    url("./../fonts/Nunito/Nunito-VariableFont_wght.ttf") format("truetype");
  font-weight: 200 900;
  font-display: swap;
}

input:focus {
  outline: none;
}

.transparent-col {
  color: transparent;
}

input[type="url"]:focus,
input[type="search"]:focus,
// input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border-color: transparent;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px transparent;
  outline: 0 none;
}

input[type="password"]:focus,
input[type="email"]:focus {
  border-color: lightgray;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px transparent;
  outline: 0 none;
}

button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
  opacity: 1 !important;
}

textarea:focus {
  border-color: lightgray;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px transparent;
  outline: 0 none;
}

.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.fw-800 {
  font-weight: 800 !important;
}
.fw-900 {
  font-weight: 900 !important;
}

::-webkit-scrollbar {
  width: 15px;
}
::-webkit-scrollbar-track {
  background-color: inherit;
  border-radius: 0 1rem 1rem 0;
}
::-webkit-scrollbar-thumb {
  background-color: #ececec;
  border-radius: 1rem;
  border: 4px solid transparent;
  background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
::-webkit-scrollbar-button {
  display: none !important;
}

input {
  box-shadow: none !important;
  font-weight: 500 !important;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.table-list {
  td,
  th {
    border: 0.1px solid #ecefff !important;
  }
}
