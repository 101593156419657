@import "../../../variables/Variable.scss";
.sidebar {
  position: sticky;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #0071a4;
  height: 100vh;
  // width: 100%;
  display: flex;
  flex-direction: column;
  .icon-logo {
    width: 100%;
    height: 15vh;
    display: flex;
    align-items: center;
    padding: 0 20px;
    img {
      width: 100%;
      object-fit: contain;
    }
  }
}

.active-color {
  background-color: #03608b;
}
.menu {
  .text-menu {
    @include f10-vw();
    color: white;
    font-weight: 500;
    &:hover {
      color: white;
    }
  }
  .text-submenu {
    @include f10-vw();
    color: white;
    font-weight: 500;
    &:hover {
      color: white;
    }
  }
  .menu-hover {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    &:hover {
      background-color: #11224d;
    }
  }
  .active-path {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    background-color: #11224d;
  }

  .active {
    .text-menu {
      color: white;
      font-weight: 700;
    }
    .text-submenu {
      color: white;
      font-weight: 700;
    }
  }
}

.menu-color {
  color: #c7c7c7;
  &:hover {
    color: white;
  }
}
.user-picture {
  width: 40px;
  height: 40px;
  background-color: white;
  img {
    border-radius: 50%;
    width: inherit;
    height: inherit;
    object-fit: cover;
  }
}
.user-name {
  color: #0071a4;
  font-weight: 600;
}

@media screen and (max-width: 980px) {
  .overlay-sidebar {
    height: 100%;
    position: fixed;
    width: 100%;
    z-index: 999;
    top: 0;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.7);
    overflow-x: hidden;
    transition: 0.5s;
    .sidebar {
      position: sticky;
      left: 0;
      top: 0;
      bottom: 0;
      background-color: #0071a4;
      height: 100vh;
      width: 75%;
      display: flex;
      flex-direction: column;
      z-index: 1000;
      .icon-logo {
        width: 100%;
        height: 15vh;
        display: flex;
        align-items: center;
        padding: 0 20px;
        img {
          width: 100%;
          object-fit: contain;
        }
      }
    }
  }
  .user-picture {
    width: 100%;
    height: 13vh;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      // border-radius: 50%;
      width: 3rem;
      height: 3rem;
      object-fit: cover;
    }
  }
  .user-name {
    color: white;
    font-weight: 600;
  }
  .mobile-header {
    background-color: white;
    position: relative;
    margin: 0 20px;
    border: 1px solid #ecefff;
    border-radius: 5px;
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

    // padding: 12px 8px;
    .css-1rvijmx-indicatorContainer {
      background-color: #177dac40;
      width: 15px;
      height: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      bottom: 4px;
      // .css-tj5bde-Svg {
      //   width: 100%;
      //   position: relative;
      //   bottom: 3px;
      // }
    }
    .hotel-name {
      color: #0071a4;
      font-weight: 700;
    }
    .hotel-location {
      @include f10-vw();
      color: #707070;
      font-weight: 500;
    }
    .rating {
      display: flex;
      flex-direction: row;
      align-items: center;
      .rate-number {
        width: 20px;
        height: 20px;
        background-color: #0071a4;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        border-radius: 3px;
        @include f10-vw();
        font-weight: bold;
      }
      .rate-word {
        color: #0071a4;
        @include f10-vw();
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .feature {
      display: flex;
      flex: wrap;
      .feature-icon {
        width: 30px;
        margin-right: 5px;
        img {
          width: 100%;
        }
      }
    }
  }
  .menu {
    .text-menu {
      @include f12-vw();
      color: #c7c7c7;
      font-weight: 500;
      &:hover {
        color: white;
      }
    }
    .text-submenu {
      @include f14-vw();
      color: #c7c7c7;
      font-weight: 700;
      &:hover {
        color: white;
      }
    }
    .active {
      .text-menu {
        color: white;
        font-weight: 700 !important;
      }
      .text-submenu {
        color: white;
        font-weight: 700;
      }
    }
  }
}
