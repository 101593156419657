@import "../variables/Variable.scss";

.info-status {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: white;
  height: 10vh;
  z-index: 10;
}
.breadcrumb-data {
  color: #959595;
  text-decoration: none;
  @include f10-vw();
  &-active {
    @include f10-vw();
    color: #0071a4;
    &:hover {
      color: #0071a4;
    }
  }
  &:hover {
    color: #959595;
  }
}
.sub-header {
  width: 100%;
  height: 17vh;
  padding: 14px 48px;
  margin-top: 10px;
  .img-container {
    padding-right: 12px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      object-fit: cover;
    }
  }
  .hotel-name {
    color: #0071a4;
    font-weight: 700;
  }
  .hotel-location {
    @include f8-vw();
    color: #707070;
    font-weight: 500;
  }
  .rating {
    display: flex;
    flex-direction: row;
    align-items: center;
    .rate-number {
      width: 27px;
      height: 27px;
      background-color: #0071a4;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $white;
      border-radius: 5px;
      @include f8-vw();
      font-weight: bold;
    }
    .rate-word {
      color: #0071a4;
      @include f8-vw();
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .feature {
    display: flex;
    .feature-icon {
      width: 30px;
      margin-right: 5px;
      img {
        width: 100%;
      }
    }
  }
}
.tab-link {
  width: 100%;
  ul {
    margin-left: 30px !important;
    width: 18rem !important;
    .tab-li {
      padding: 0;
      .tab-container-walk-in,
      .tab-container-other-ota {
        @include f8-vw();
        display: flex;
        border: 1px solid transparent;
        color: #5e5e5e;
        background-color: #ececec;
        text-align: center;
        justify-content: center;
        align-items: center;
        font-weight: 600;
      }
      .active {
        background-color: #79319f;
        color: white;
      }
      .tab-container-walk-in {
        border-radius: 5px 0 0 5px;
      }
      .tab-container-other-ota {
        border-radius: 0 5px 5px 0;
      }
    }
  }
  .btn-position {
    margin: 0 30px 0 0;
    padding: 0;
    .room-btn {
      @include f8-vw();
      background-color: #e8feea !important;
      font-weight: 600;
      border-radius: 5px;
      margin-right: 5px;
      color: #16912f;
      &:hover {
        background-color: #e8feea !important;
        font-weight: 600;
        color: #16912f;
        margin-right: 5px;
      }
      .room-text {
        color: #16912f;
      }
    }
  }
}
.group-input {
  width: 100%;
  .react-tel-input {
    font-family: inherit;
    .form-control {
      border: 1px solid #ced4da !important;
      @include f8-vw();
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 0;
      width: 100%;
      color: black;
      height: calc(1.5em + 0.7rem + 2px) !important;
      font-weight: 400 !important;
    }
    .flag-dropdown {
      background-color: transparent;
      border-right: none;
      border-radius: 5px 0 0 5px;
      .selected-flag {
        border-radius: 5px 0 0 5px;
        border: none;
        border-right: none;
      }
    }
  }
}

.react-datepicker-wrapper {
  border-radius: 5px;
  width: 100px;
  .react-datepicker__input-container {
    display: flex;
    justify-content: center;

    .date-checkin-new {
      text-align: center;
      width: 100%;
      border: 0px !important;
      color: $grey;
    }
    input {
      border: 0px !important;
    }
  }
}
.date-single-input {
  .react-datepicker-wrapper {
    border-radius: 5px;
    // width: 85px;
    width: 100% !important;
    .react-datepicker__input-container {
      display: flex;
      justify-content: center;
      .date-checkin-new {
        // padding-top: 0.3rem;
        text-align: center;
        width: 100%;
        border: none;
        color: $grey;
      }

      .react-datepicker__close-icon::after {
        background-color: #79319f;
      }
    }
  }
}
.date-border {
  border: 1px solid #d5d5d5;
  width: 100%;
  border-radius: 5px;
  height: 1.81rem;
  padding-top: 0.1rem;
  .date-view {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    .react-datepicker-wrapper {
      width: fit-content;
      background-color: transparent;
      .react-datepicker__input-container {
        min-width: 5.2rem;
        max-width: 5.4rem;
        width: 100%;
        .date-range {
          display: flex;
          align-items: center;
          width: 100%;
          @include f8-vw();
          border: none;
          color: $grey;
        }
      }
    }
  }
}

.btn-outline-purple {
  border: 1px solid $primaryColor;
  border-radius: 5px;
  color: $primaryColor;
}

.day-box {
  display: flex;
  justify-content: center;
  .day-border {
    cursor: pointer;
    background: #f8f7fa;
    width: 2.95rem;
    height: 1.8rem;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #62148f;
    border: 1px solid #62148f;
  }
  .active-indicator {
    background: #62148f;
    color: white;
    border: none;
  }
  .day-disable {
    background: #d5d5d5;
    width: 2.95rem;
    height: 1.8rem;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: not-allowed !important;
  }
}

.tab-pane {
  height: 60vh;
}
.dropdown-toggle::after {
  color: #0066ff !important;
}
.date-wrapper {
  .react-datepicker-wrapper {
    width: fit-content;
    background-color: transparent;
    .react-datepicker__input-container {
      width: 100%;
      .date-range {
        display: flex;
        align-items: center;
        width: 100%;
        @include f8-vw();
        border: none;
        color: $grey;
      }
    }
  }
}

@media screen and (max-width: 980px) {
  .info-status {
    position: unset;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    background-color: white;
    height: fit-content;
  }
  .breadcrumb-data {
    @include f12-vw();
    &-active {
      @include f12-vw();
      color: #0071a4;
      &:hover {
        color: #0071a4;
      }
    }
    &:hover {
      color: #959595;
    }
  }
  .tab-link {
    width: 100%;
    padding: 0 20px;
    ul {
      margin: 0 !important;
      width: 100% !important;
      .tab-li {
        padding: 0;
        .tab-container-walk-in,
        .tab-container-other-ota {
          @include f12-vw();
          display: flex;
          border: 1px solid transparent;
          color: #5e5e5e;
          background-color: #ececec;
          text-align: center;
          justify-content: center;
          align-items: center;
          font-weight: 600;
        }
        .active {
          background-color: #79319f;
          color: white;
        }
        .tab-container-walk-in {
          border-radius: 5px 0 0 5px;
        }
        .tab-container-other-ota {
          border-radius: 0 5px 5px 0;
        }
      }
    }
    .btn-position {
      margin: 0 4px;
      padding: 0;
      .room-btn {
        @include f8-vw();
        background-color: #ceffb9 !important;
        font-weight: 600;
        border-radius: 5px;
        margin-right: 5px;
        color: #16912f;
        &:hover {
          background-color: #e8feea !important;
          font-weight: 600;
          color: #16912f;
          margin-right: 5px;
        }
        .room-text {
          color: #16912f;
          display: none;
        }
      }
    }
  }
  .group-input {
    width: 100%;
    .react-tel-input {
      font-family: inherit;
      .form-control {
        border: 1px solid #ced4da !important;
        @include f12-vw();
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 0;
        width: 100%;
        color: black;
        height: calc(1.5em + 0.7rem + 2px) !important;
        font-weight: 400 !important;
      }
      .flag-dropdown {
        background-color: transparent;
        border-right: none;
        border-radius: 5px 0 0 5px;
        .selected-flag {
          border-radius: 5px 0 0 5px;
          border: none;
          border-right: none;
        }
      }
    }
  }
  .mobile-card {
    display: flex;
    flex-direction: column;
    margin: 12px 0;
    &-header {
      background-color: #0071a4;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    .day-box-mobile {
      width: 100%;
      .day-border {
        cursor: pointer;
        background: #f8f7fa;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: black;
      }
      .active-indicator {
        background: #53bdb6;
        color: white;
        border: none;
      }
      .day-disable {
        background: #d5d5d5;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: white;
        cursor: not-allowed !important;
      }
    }
    &-info {
      border: 1px solid #ecefff;
      display: flex;
    }
  }

  .react-datepicker__input-container {
    margin-left: 5px;
    .date-checkin-new {
      width: 100%;
      border: 0px !important;
    }
    input {
      text-align: center;
    }
  }
  .card-hms {
    // box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    border: 1px solid #ecefff;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    &-header {
      color: #62148f;
      font-weight: 700;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4px 12px;
    }
    &-body {
      padding: 0 4px;
      margin-top: 10px;
    }
    &-list {
      @include f12-vw();
      padding: 4px 8px;
      display: flex;
    }
    .combo-box-mobile {
      .dropdown-menu {
        top: 130% !important;
      }
      .dropdown-menu-center {
        left: 25% !important;
        right: auto !important;
        text-align: center !important;
        transform: translate(-50%, 0) !important;
      }
      .menu {
        padding: 5px 0;
      }
      .menu:hover {
        background-color: #0071a4 !important;
        color: white !important;
      }
    }
  }
}
