// Class color
@import url("react-datepicker/dist/react-datepicker.css");
@import "../../node_modules/bootstrap/scss/bootstrap.scss";
// primary (purple)
$primaryColor: #5f0593 !important;
$primaryColor-1: #742b9e !important;
$primaryColor-2: #8c50a5 !important;
$primaryColor-3: #a066c1 !important;
$primaryColor-4: #fbe3ff !important;
$primaryColor-5: #e8dbf0 !important;

// secondary (orange)
$secondaryColor: #ef9403 !important;
$secondaryColor-1: #eea42e !important;
$secondaryColor-2: #f5b755 !important;
$secondaryColor-3: #ffd695 !important;
$secondaryColor-4: #ffebcb !important;
$secondaryColorButton: #f4b551 !important;

// general color
$blue: #0088ff !important;
$green: #36ab1f !important;
$black: #0000 !important;
$grey: #5e5e5e !important;
$light-grey: #b7b7b7 !important;
$light: #ececec !important;
$white: #ffff !important;
$smooth-gray: #f9faff !important;
$smooth-purple: #f1ddf3 !important;
$dark-blue: #177dac !important;

// Class Fonts Size
@mixin f4-vw() {
  font-size: 0.51vw;
  font-size: calc(3pt + 0.216vw);
}
@mixin f6-vw() {
  font-size: 0.59vw !important;
  font-size: calc(4pt + 0.236vw) !important;
}
@mixin f7-vw() {
  font-size: 0.66vw !important;
  font-size: calc(5pt + 0.265vw) !important;
}
@mixin f8-vw() {
  font-size: 0.78vw !important;
  font-size: calc(6pt + 0.312vw) !important;
}
@mixin f9-vw() {
  font-size: 0.88vw;
  font-size: calc(6pt + 0.352vw);
}
@mixin f10-vw() {
  font-size: 0.98vw;
  font-size: calc(7pt + 0.392vw);
}
@mixin f12-vw() {
  font-size: 1.18vw !important;
  font-size: calc(8pt + 0.472vw) !important;
}
@mixin f14-vw() {
  font-size: 1.37vw !important;
  font-size: calc(9pt + 0.548vw) !important;
}
@mixin f16-vw() {
  font-size: 1.56vw;
  font-size: calc(11pt + 0.624vw);
}
@mixin f18-vw() {
  font-size: 1.76vw;
  font-size: calc(12pt + 0.704vw);
}
@mixin f20-vw() {
  font-size: 1.96vw;
  font-size: calc(13pt + 0.784vw);
}
@mixin f24-vw() {
  font-size: 2.36vw;
  font-size: calc(16pt + 0.944vw);
}
@mixin f28-vw() {
  font-size: 2.74vw;
  font-size: calc(19pt + 1.1vw);
}
@mixin f32-vw() {
  font-size: 3.1737vw;
  font-size: calc(22pt + 1.26948vw);
}
@mixin f48-vw() {
  font-size: 4.72vw;
  font-size: calc(32pt + 1.88vw);
}

.f4-vw {
  @include f4-vw();
}
.f6-vw {
  @include f6-vw();
}
.f7-vw {
  @include f7-vw();
}

.f8-vw {
  @include f8-vw();
}

.f9-vw {
  @include f9-vw();
}

.f10-vw {
  @include f10-vw();
}

.f12-vw {
  @include f12-vw();
}

.f14-vw {
  @include f14-vw();
}

.f16-vw {
  @include f16-vw();
}

.f18-vw {
  @include f18-vw();
}

.f20-vw {
  @include f20-vw();
}

.f24-vw {
  @include f24-vw();
}

.f28-vw {
  @include f28-vw();
}

.f32-vw {
  @include f32-vw();
}

.f48-vw {
  @include f48-vw();
}

.button-topright {
  border-top-right-radius: 5px !important;
}
.button-bottomright {
  border-bottom-right-radius: 5px !important;
}
.right-radius {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.left-radius {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}
.input-right-radius {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.rounded-header {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}
.rounded-input {
  border-radius: 5px !important;
}
.rounded-page {
  border-radius: 10px !important;
}
.rounded-comp {
  border-radius: 10px !important;
}
.rounded-bottom {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.top-left-rounded {
  border-top-left-radius: 5px !important;
}
.top-right-rounded {
  border-top-right-radius: 5px !important;
}
.bottom-right-rounded {
  border-bottom-right-radius: 5px !important;
}

// Class Text Color
.purple {
  color: $primaryColor;
}
.orange {
  color: $secondaryColor;
}
.white {
  color: $white;
}
.grey {
  color: #949494;
}
.green {
  color: $green;
}
.tosca {
  color: #51b7ab;
}
.yellow {
  color: #eba330 !important;
}
.red {
  color: #ff0000;
}
.disable-color {
  color: $light-grey;
}
.smooth-grey {
  background-color: $light;
}
.blue {
  color: $blue;
}
.dark-blue {
  color: $dark-blue;
}
.navy-blue {
  color: #174a81;
}
.blue-title {
  color: #0071a4 !important;
}
.black-text {
  color: #5e5e5e;
}
.smooth-purple {
  background-color: $smooth-purple;
}
.grey-text {
  color: #959595;
}
.black-value {
  color: #000070;
}
.table-bg {
  background-color: #f9faff;
}
// separator

.separator-list {
  border-top: 1px solid $secondaryColor;
  padding-top: 20px;
}
.separator {
  background-color: $secondaryColor;
}
.separator-grey {
  background-color: #e9e9e9 !important;
}
.separator-purple {
  background-color: $primaryColor;
}
.separator-blue {
  // background-color: #ecefff !important;
  border: 1px solid #ecefff !important;
}

// Background
.bg-primaryCol {
  background-color: $primaryColor;
}
.bglight {
  background-color: $light;
  opacity: 0.5;
}
.bg-secondCol {
  background-color: $secondaryColor;
}
.bg-secondCol-2 {
  background-color: $secondaryColor-2;
}
.bg-primaryCol-2 {
  background-color: $primaryColor-2;
}
.bg-primaryCol-3 {
  background-color: $primaryColor-3;
}
.bg-primaryCol-4 {
  background-color: $primaryColor-4;
}
.bg-secondColNew {
  background-color: #ffb83e !important;
}
.bg-buttonSecondCol {
  background-color: $secondaryColorButton;
}

.bg-green {
  background-color: $green;
}

.bg-red {
  background-color: #ff0000 !important;
}

.bg-lightpopup {
  background-color: #f8f7fa !important;
  border-radius: 5px;
}

.bg-grey {
  background-color: $grey;
}

// position

.right-corner {
  position: absolute;
  bottom: 1rem;
  right: 3rem;
}

.bot {
  position: relative;
}

.btn-transparent {
  background-color: transparent;
}

.left-corner {
  position: absolute;
  bottom: 5%;
  left: 10%;
}

.scroll-page {
  position: sticky;
  overflow-y: auto;
}
.sticky-table {
  position: -webkit-sticky;
  position: sticky;
  z-index: 10;
  top: 0;
}

.scroll-page::-webkit-scrollbar {
  display: none;
}

.btn-btm {
  position: absolute;
  bottom: 2%;
  right: 5%;
}

.btn-allot {
  width: 10rem;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  content: url("https://api.iconify.design/fluent/chevron-up-down-24-filled.svg?color=%23ef9403");
  background: none;
  // display: none;
}

.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  .border-box {
    background: #d5d5d5;
    // border: 1px solid #acacac;
    border-radius: 4px;
    width: 20px;
    height: 20px;
    .indicator {
      background-color: #51b7ab;
      // border: 1px solid #51b7ab;
      border-radius: 4px;
      width: 100%;
      height: 100%;
      transform: scale(0);
      transition: transform 100ms;
    }
    .checked {
      content: url("https://api.iconify.design/ci/check-bold.svg?color=white");
      transform: scale(1);
    }
  }
}

.disable {
  color: lightgray !important;
}

.btn-gradient {
  // background: rgb(116, 43, 158);
  // background: linear-gradient(
  //   0deg,
  //   rgba(116, 43, 158, 1) 35%,
  //   rgba(140, 80, 165, 1) 100%
  // );
  background-color: #62148f !important;
}

.dropdown-toggle::after {
  display: none;
  position: absolute;
  right: 5%;
  top: 45%;
  color: #62148f;
}

.data-class {
  font-size: 0.7rem !important;
}

.debounce-input {
  position: absolute;
  padding: 0;
  width: 100%;
  border: 1px solid $light;
  z-index: 9;

  .list-debounce {
    padding: 0;
    display: flex;
    justify-content: center;
    cursor: pointer;
    color: #62148f;
  }
  .list-debounce:hover {
    background-color: #62148f;

    width: 100%;
    color: white;
  }
}
input[type="checkbox"] {
  accent-color: #0071a4 !important;
  vertical-align: middle;
  transform: scale(1.3);
  margin: 0 4px;
}
.compensation-checkbox {
  input[type="checkbox"] {
    accent-color: $primaryColor-1;
    vertical-align: middle;
    transform: scale(1.3);
    margin: 0 4px;
  }
}

.alert {
  padding: 0.25rem 1.25rem;
  margin: 0;
}
.combo-box {
  .dropdown {
    padding: 0 8px;
    .dropdown-menu {
      .menu {
        padding: 5px 0;
      }
      .menu:hover {
        background-color: #0071a4 !important;
        color: white !important;
      }
    }
  }
}
.combo-box-team {
  .dropdown {
    padding: 0 8px;
    .dropdown-menu {
      .menu {
        padding: 5px 0;
      }
      .menu:hover {
        background-color: #62148f !important;
        color: white !important;
      }
    }
    .show {
      position: absolute;
      transform: translate3d(-5rem, 1rem, 0) !important;
      top: 0px;
      right: 0px;
      will-change: transform;
    }
  }
}
.combobox-download {
  .dropdown {
    padding: 0 8px;
    .dropdown-menu {
      min-width: 120px !important;
      .menu {
        padding: 5px 0;
      }
      .menu:hover {
        background-color: #1f6e43 !important;
        color: white !important;
      }
    }
    .dropdown-toggle::after {
      position: relative;
      color: white !important;
      right: 0;
    }
  }
}

.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--selected,
.react-datepicker__month-text--in-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--selected,
.react-datepicker__quarter-text--in-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__year-text--in-range,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--selected {
  background-color: #62148f !important;
  color: #ffff;
}

.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--selected,
.react-datepicker__month-text--in-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--selected,
.react-datepicker__quarter-text--in-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__year-text--in-range,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--selected {
  background-color: #62148f !important;
  color: #ffff;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #62148f !important;
  color: #ffff;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #62148f !important;
  color: #ffff;
}
.watermark {
  position: relative;
  .img-watermark {
    position: absolute;
    width: 100px;
    right: -0.5rem;
    top: -3rem;
  }
}
.search-master {
  .btn:focus,
  .btn:active {
    outline: none !important;
    box-shadow: none !important;
  }
  .btn-outline {
    border: 1px solid #c6c6c6;
    outline: 0 none !important;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px transparent;
    background-color: white;
    span {
      color: #62148f;
    }
    &:hover {
      background-color: #62148f;
      span {
        color: white;
      }
    }
  }
  .form-control {
    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }
}

.add-new {
  .btn:focus,
  .btn:active {
    outline: none !important;
    box-shadow: none !important;
  }
  button {
    background-color: #62148f;
    span {
      color: white;
    }
  }
}
// .dropzone-ui-extra {
//   border: 1px solid rgba(93, 100, 117, 1) !important;
// }
.dz-ui-label {
  @include f8-vw();
}

.upload-ota {
  .file-item-list-container.file-item-list-grid {
    min-height: 60px !important;
    max-height: 80px !important;
  }
  .dz-ui-file-item-container {
    position: relative;
    top: 5px;
  }
  .file-item-full-container-container {
    width: 80px !important;
    height: 80px !important;
  }
  .file-item-full-container-container .file-item-icon-container {
    height: 70px !important;
    width: 70px !important;
  }
  .file-item-list-container.file-item-list-list {
    min-height: 60px;
    max-height: 80px !important;
  }
  .file-item-full-container-container {
    .file-item-name {
      @include f6-vw();
      height: 13px;
      width: 100%;
    }
  }
}

.report-border {
  border: 1px dashed grey;
}

.scroll-table {
  position: sticky;
  overflow-y: auto;
  overflow-x: auto;
  white-space: nowrap;
  scroll-behavior: smooth !important;
}

.scroll-table::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.scroll-table::-webkit-scrollbar-track {
  background-color: inherit;
  border-radius: 0 1rem 1rem 0;
}

.scroll-table::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 1rem;
  border: 1px solid transparent;
  background-clip: content-box;
}
.scroll-table::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
.scroll-table::-webkit-scrollbar-button {
  display: none !important;
}

.status-out {
  color: rgb(226, 44, 31);
  background-color: rgba(226, 44, 31, 0.25);
}

.status-vacant {
  color: rgb(21, 135, 121);
  background-color: rgba(21, 135, 121, 0.25);
}

.status-ocupied {
  color: rgb(116, 43, 158);
  background-color: rgba(116, 43, 158, 0.25);
}
.status-actual {
  color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.25);
}

.sticky-td {
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  z-index: 0;
}
.sticky-th {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 99 !important;
}

.set-overflow {
  overflow: scroll !important;
  white-space: nowrap;
}

.btn-count-active {
  background-color: #62148f;
}
.btn-count-disable {
  background-color: #b7b7b7;
}

.status-vd {
  color: #ec7600;
  background-color: #ffd37d;
}
.status-od {
  color: #ec7600;
  background-color: #ffd37d;
}
.status-vc {
  color: #2ba62c;
  background-color: #b7ffb7;
}
.status-oc {
  color: #2ba62c;
  background-color: #b7ffb7;
}
.status-vr {
  color: #ffffff;
  background-color: #64c865;
}
.status-os {
  color: #e60023;
  background-color: #ffd6d6;
}
.status-oo {
  color: #ffffff;
  background-color: #ff6d6d;
}

.room-table {
  table,
  tbody,
  tr,
  td {
    box-sizing: border-box;
  }
}

.reservasi-color {
  background-color: #11224d !important;
}
.shift-color {
  background-color: #f46b0d !important;
}
.master-color {
  background-color: #018119 !important;
}
.report-color {
  background-color: #00ac32 !important;
}
.housekeeping-color {
  background-color: #992c12 !important;
}
.marketing-color {
  background-color: #f15a5a !important;
}
.checkout-color {
  background-color: #193a6f !important;
}
.lossfound-color {
  background-color: #2c599d !important;
}

.click-table {
  &:hover {
    background-color: #ffec8b !important;
    // color: white;
  }
}

.infinite-scroll::-webkit-scrollbar {
  width: 15px;
}

.infinite-scroll::-webkit-scrollbar-track {
  background-color: inherit;
  border-radius: 0 1rem 1rem 0;
}

.infinite-scroll::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 1rem;
  border: 6px solid transparent;
  background-clip: content-box;
}
.infinite-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
.infinite-scroll::-webkit-scrollbar-button {
  display: none !important;
}

.top-rounded {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.stepper {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  width: 100%;
  .stepper-menu {
    width: 100% !important;
    position: relative;
    .circle-step {
      background-color: #ececec;
      color: #afafaf;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
      font-weight: bold;
      &-active {
        font-weight: bold !important;
        background-color: #efe5f4 !important;
        color: $primaryColor;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5px;
      }
    }
    .title-stepper {
      color: #959595;
      font-weight: bold !important;
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: center;
      &-active {
        align-items: center !important;
        overflow: hidden;
        display: flex;
        position: relative;
        font-weight: bold !important;
        color: $primaryColor;
      }
      &-active .line-stepper,
      .line-stepper {
        // vertical-align: baseline;
        display: block;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          width: 100%;
          top: 50%;
          margin-left: 5px;
          border-top: 1px solid #ccc;
        }
      }
    }
  }
}

.not-opening {
  pointer-events: none !important;
  opacity: 0.4 !important;
}

.hotel-gallery-image {
  .image-gallery-image {
    max-width: 100%;
    height: 25rem;
    object-fit: contain;
    border-radius: 3px;
  }
  .image-gallery-description {
    bottom: 0;
    padding: 5px 10px;
    @include f4-vw();
  }
  .image-gallery-index {
    padding: 5px 10px;
  }
  .image-gallery-thumbnail-image {
    object-fit: contain;
    width: 3rem;
    height: 3rem;
  }

  .image-gallery-left-nav,
  .image-gallery-right-nav {
    padding: 0 !important ;
    .image-gallery-svg {
      width: 30px;
      height: 30px;
    }
  }
  .active {
    border: 2px solid $secondaryColor;
    border-radius: 5px;
  }
}
.hotel-gallery-image-mobile {
  .image-gallery-image {
    max-width: 100%;
    height: 20rem;
    object-fit: contain;
    border-radius: 3px;
  }
  .image-gallery-description {
    bottom: 0;
    padding: 5px 10px;
    @include f4-vw();
  }
  .image-gallery-index {
    padding: 5px 10px;
  }
  .image-gallery-thumbnail-image {
    object-fit: contain;
    width: 3rem;
    height: 3rem;
  }

  .image-gallery-left-nav,
  .image-gallery-right-nav {
    padding: 0 !important ;
    .image-gallery-svg {
      width: 30px;
      height: 30px;
    }
  }
  .active {
    border: 2px solid $secondaryColor;
    border-radius: 5px;
  }
}

.scroll-area {
  overflow-y: auto;
}
.scroll-area::-webkit-scrollbar {
  width: 1rem;
}

.scroll-area::-webkit-scrollbar-track {
  background-color: inherit;
  border-radius: 0 1rem 1rem 0;
}

.scroll-area::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 1rem;
  border: 6px solid transparent;
  background-clip: content-box;
}
.scroll-area::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
.scroll-area::-webkit-scrollbar-button {
  display: none !important;
}

.scroll-popup {
  overflow-y: auto;
}
.scroll-popup::-webkit-scrollbar {
  width: 15px;
}
.scroll-popup::-webkit-scrollbar-track {
  background-color: inherit;
  border-radius: 0 1rem 1rem 0;
}
.scroll-popup::-webkit-scrollbar-thumb {
  background-color: $primaryColor-3;
  border-radius: 1rem;
  border: 6px solid transparent;
  background-clip: content-box;
}
.scroll-popup::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
.scroll-popup::-webkit-scrollbar-button {
  display: none !important;
}

.top-menu {
  background-color: white;
}

.overlay {
  height: 100%;
  width: 0;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: -100%;
  top: 0;
  background-color: rgb(0, 0, 0); /* Black fallback color */
  background-color: rgba(0, 0, 0, 0.7); /* Black w/opacity */
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s;
  .header-overlay {
    background: rgb(175, 114, 178);
    background: linear-gradient(
      180deg,
      rgba(175, 114, 178, 1) 0%,
      rgba(136, 65, 175, 1) 73%
    );
  }
  .menu-overlay {
    position: relative;
    transform: translate(-100%, 0);
    color: #707070;
  }
}

.active-overlay {
  width: 100%;
  transition: 0.5s !important;
  left: 0;
  .menu-overlay {
    position: relative;
    transform: translate(0, 0);
    li {
      .active {
        color: #5f0593 !important;
      }
    }
  }
}
.transition-test {
  transition: 0.5s !important;
}

.status-nfc {
  background-color: #ff4a4a;
  color: white;
}
.status-cop {
  background-color: #5eb2f8;
  color: white;
}
.status-nfi {
  background-color: #0000ff;
  color: white;
}
.status-cdn {
  background-color: #228b22;
  color: white;
}
.status-rjk {
  background-color: #ffa200;
  color: white;
}
.note-more {
  max-width: 30rem;
}

.loading-overlay {
  .overlay {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(34, 34, 34, 0.404);
    z-index: 1000;
  }

  .overlay__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .overlay__content {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .spinner {
    width: 75px;
    height: 75px;
    display: inline-block;
    border-width: 2px;
    border-color: rgba(255, 255, 255, 0.05);
    border-top-color: #fff;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
}

// .table-border-line {
//   border: 1px solid #cacaca;
//   border-radius: 5px;
// }

.disable-date {
  background-color: #ececec !important;
}

.col-md-half {
  @include make-col-ready();

  @include media-breakpoint-up(md) {
    @include make-col(0.5);
  }
}

.col-md-2half {
  @include make-col-ready();

  @include media-breakpoint-up(md) {
    @include make-col(2.5);
  }
}

.col-md-3half {
  @include make-col-ready();

  @include media-breakpoint-up(md) {
    @include make-col(3.5);
  }
}

.col-md-4half {
  @include make-col-ready();

  @include media-breakpoint-up(md) {
    @include make-col(4.5);
  }
}

.col-md-5half {
  @include make-col-ready();

  @include media-breakpoint-up(md) {
    @include make-col(5.5);
  }
}
.col-md-6half {
  @include make-col-ready();

  @include media-breakpoint-up(md) {
    @include make-col(6.5);
  }
}

.col-md-7half {
  @include make-col-ready();

  @include media-breakpoint-up(md) {
    @include make-col(7.5);
  }
}

.col-md-8half {
  @include make-col-ready();

  @include media-breakpoint-up(md) {
    @include make-col(8.5);
  }
}

.col-md-9half {
  @include make-col-ready();

  @include media-breakpoint-up(md) {
    @include make-col(9.5);
  }
}

.btn-default-selected {
  background-color: #c7c7c7;
  color: white;
}

.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.css-8etcny-placeholder {
  width: 100%;
}
